import { Controller } from "@hotwired/stimulus";

import parsePhoneNumber, { AsYouType } from "libphonenumber-js";

export default class extends Controller {
  static targets = ["phoneInput", "phoneFlag"];

  onSubmit(event) {
    this.phoneInputTargets.forEach((input) => {
      const parsed = parsePhoneNumber(input.value, "US");
      if (parsed && parsed.isValid()) {
        input.setCustomValidity("");
        input.value = parsed.number;
      } else {
        input.setCustomValidity("Invalid phone number");
        input.reportValidity();
        event.preventDefault();
      }
    });
  }

  onPhoneNumberInput(event) {
    const phoneNumber = event.target.value;
    const output = new AsYouType("US").input(phoneNumber);
    event.target.value = output;

    const parsed = parsePhoneNumber(phoneNumber, "US");
    if (parsed && parsed.isValid()) {
      event.target.setCustomValidity("");

      const flagIcon = this.getFlagIcon(event.target);
      if (flagIcon) {
        flagIcon.classList.value = `flag flag-country-${parsed.country.toLowerCase()}`;
        flagIcon.title = parsed.country;
      }
    }
  }

  getFlagIcon(input) {
    const parent = input.closest("[data-controller='libphone']");
    if (!parent) {
      return null;
    }

    return parent.querySelector("[data-libphone-target='phoneFlag']");
  }
}
