import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity", "total"];

  onQuantityChange() {
    this.updateTotal();
    this.dispatchQuantityChangedEvent();
  }

  calculateTotal() {
    return this.quantityTargets.reduce((sum, target) => {
      let value = parseInt(target.value || 0, 10);
      return sum + (isNaN(value) ? 0 : value);
    }, 0);
  }

  updateTotal() {
    this.totalTarget.textContent = this.calculateTotal();
  }

  dispatchQuantityChangedEvent() {
    const event = new CustomEvent("quantity:changed", { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
