import { Controller } from "@hotwired/stimulus";

import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element);
  }

  open() {
    if (!this.modal.isOpened) {
      this.element.addEventListener(
        "shown.bs.modal",
        () => {
          const autofocusElement = this.element.querySelector("[autofocus]");
          if (autofocusElement) {
            autofocusElement.focus();
          }
        },
        { once: true },
      );

      this.modal.show();
    }
  }

  close(event) {
    const moreElement = this.element.querySelector("input[name='more']");
    const keepOpen = moreElement && moreElement.value === "true";
    if (event.detail.success && !keepOpen) {
      this.modal.hide();
    }
  }
}
