import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="address"
export default class extends Controller {
  static targets = [
    "street",
    "street_other",
    "city",
    "state",
    "zip",
    "country",
    "flag",
  ];

  connect() {
    if (this.mapInit) return;

    this.initMaps();
  }

  initMaps() {
    const input = document.getElementById("address_street");
    if (!input) return;

    input.addEventListener("focus", (event) => {
      event.target.setAttribute("autocomplete", "new-password");
    });
    this.autocomplete = new google.maps.places.Autocomplete(this.streetTarget, {
      fields: ["address_components"],
      types: ["address"],
    });
    this.autocomplete.addListener(
      "place_changed",
      this.placeSelected.bind(this),
    );
    console.log("AutoComplete initialized");
    this.mapInit = true;
  }

  placeSelected() {
    const place = this.autocomplete.getPlace();

    let address1 = "";
    let postcode = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "subpremise": {
          this.street_otherTarget.value = `#${component.long_name.toUpperCase()}`;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          this.cityTarget.value = component.long_name;
          break;
        case "administrative_area_level_1": {
          this.stateTarget.value = component.short_name;
          break;
        }
        case "country":
          this.countryTarget.value =
            component.short_name === "US" ? "US" : component.long_name;
          this.flagTarget.classList.value = `flag flag-country-${component.short_name.toLowerCase()}`;
          this.flagTarget.title = component.long_name;
          break;
      }

      this.streetTarget.value = address1;
      this.zipTarget.value = postcode;
    }
  }
}
