const checkPasswordStrength = () => {
  const password = document.getElementById("user_password");
  if (!password || password.dataset.hasClickHandler) return;

  const meter = document.getElementById("password_strength");
  if (!meter) return;

  password.addEventListener("input", () => {
    const val = password.value;
    const result = window.zxcvbn(val);
    meter.value = result.score;
    meter.classList.toggle("d-none", val === "");
  });
  password.dataset.hasClickHandler = true;
};

const setupPasswordTogglers = () => {
  const togglers = document.querySelectorAll("[data-type-toggler]");
  if (!togglers) return;

  togglers.forEach((toggler) => {
    const target = document.querySelector(toggler.dataset.target);
    if (!target || toggler.dataset.hasClickHandler) return;

    toggler.addEventListener("click", (e) => {
      e.preventDefault();

      const type = target.getAttribute("type") === "text" ? "password" : "text";
      target.setAttribute("type", type);

      const bi = toggler.querySelector(".bi");
      if (!bi) return;

      bi.classList.toggle("bi-eye");
      bi.classList.toggle("bi-eye-slash");
    });

    toggler.dataset.hasClickHandler = true;
  });
};

document.addEventListener("turbo:load", () => {
  checkPasswordStrength();
  setupPasswordTogglers();
});
