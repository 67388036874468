import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["destroyField", "row"];

  delete(event) {
    event.preventDefault();
    this.destroyFieldTarget.value = 1;
    this.rowTarget.style.display = "none";
  }
}
