// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

/* tslint:disable:no-unused-variable */
import * as bootstrap from "bootstrap";

import "./passwords";
import "./search";
import "./locations";

window.initMap = function () {
  const event = new Event("map:init", { bubbles: true, cancelable: false });
  window.dispatchEvent(event);
};
