import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cost", "price"];

  connect() {
    console.log("Cost Calculator Controller connected");
    this.markup = parseFloat(this.element.dataset.markup);
  }

  calculate() {
    const cost = parseFloat(this.costTarget.value);
    if (!isNaN(cost)) {
      const retailPrice = (cost * this.markup).toFixed(2);
      this.priceTarget.value = retailPrice;
    }
  }
}
