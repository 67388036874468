import { Sortable, MultiDrag } from "sortablejs";
Sortable.mount(new MultiDrag());

const createSortable = () => {
  const locations = document.getElementById("locations");
  if (!locations) return;

  if (typeof Sortable === "undefined") {
    console.log("SortableJs library not loaded!");
    return;
  }

  Sortable.create(locations, {
    multiDrag: true,
    selectedClass: "selected",
    animation: 150,
    handle: ".bi-grip-vertical",
    dataIdAttr: "data-id",
    store: {
      set: (sortable) => {
        updateSortOrder(sortable.toArray());
      },
    },
  });
};

const updateSortOrder = (order) => {
  fetch("/locations/update_sort_order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    },
    body: JSON.stringify({ order: order }),
  }).then((response) => {
    if (!response.ok) {
      console.log("Failed to update sort order");
    }
  });
};

document.addEventListener("turbo:load", () => {
  createSortable();
});
