import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="search"
export default class extends Controller {
  hideValidationMessage(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  showResults(_e) {
    const searchResults = document.getElementById("search-results");
    const collapse = bootstrap.Collapse.getOrCreateInstance(searchResults);
    collapse.show();
  }

  hideResults(event) {
    if (typeof event === "undefined") {
      console.log(event);
      return;
    }
    let type = event.inputType;

    let originalEvent = event.originalEvent;
    if (typeof originalEvent !== "undefined") {
      type = type || originalEvent.inputType;
    }

    if (typeof type === "undefined") {
      const searchResults = document.getElementById("search-results");
      const collapse = bootstrap.Collapse.getOrCreateInstance(searchResults);
      collapse.hide();
    }
  }
}
