// import * as bootstrap from "bootstrap";

document.addEventListener("turbo:load", () => {
  // document.addEventListener("submit", function (event) {
  //   if (event.target.id !== "search-form") {
  //     return;
  //   }
  //
  //   event.preventDefault();
  //   const searchResults = document.getElementById("search-results");
  //
  //   const resultsContainer = searchResults.querySelector(".results-container");
  //   resultsContainer.innerHTML = `<h5 class="card-title placeholder-glow">
  //                                   <span class="placeholder col-6"></span>
  //                                 </h5>
  //                                 <p class="card-text placeholder-glow">
  //                                   <span class="placeholder col-7"></span>
  //                                   <span class="placeholder col-4"></span>
  //                                   <span class="placeholder col-4"></span>
  //                                   <span class="placeholder col-6"></span>
  //                                   <span class="placeholder col-8"></span>
  //                                 </p>`;
  //
  //   const collapse = bootstrap.Collapse.getOrCreateInstance(searchResults);
  //   collapse.show();
  // });
});
