import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "customDateRange"];

  connect() {
    console.log("ReportFilterController connected");
    this.toggleCustomDateRange();
  }

  toggleCustomDateRange() {
    const selectedElement = document.querySelector(
      'input[name*="[filter_type]"]:checked',
    );

    if (selectedElement) {
      const selectedValue = selectedElement.value;
      if (selectedValue === "custom_date_range") {
        this.customDateRangeTarget.classList.remove("d-none");
      } else {
        this.customDateRangeTarget.classList.add("d-none");
      }
    }
  }
}
