import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static targets = ["input", "colorsGroup"];

  connect() {
    this.updateMaxTags();
    this.tagify = new Tagify(this.inputTarget, {
      delimiters: ",",
      whitelist: [],
      maxTags: this.getMaxTags(),
      dropdown: { enabled: 0 },
    });
  }

  colorCountChanged(_event) {
    this.updateMaxTags();
  }

  validateBeforeSubmit(event) {
    const chosenColors = this.getMaxTags();
    const currentTagCount = this.tagify.value.length;

    if (currentTagCount > chosenColors) {
      event.preventDefault();
      this.showError(
        "You have more colors specified than the selected color count.",
      );
    } else {
      this.resetError();
    }
  }

  updateMaxTags() {
    const maxTags = this.getMaxTags();
    if (this.tagify) {
      this.tagify.settings.maxTags = maxTags;
    }
  }

  getMaxTags() {
    return (
      parseInt(this.colorsGroupTarget.querySelector(":checked")?.value, 10) || 8
    );
  }

  showError(message) {
    let errorNotice = document.querySelector("#color-names-error");
    if (!errorNotice) {
      errorNotice = document.createElement("div");
      errorNotice.id = "color-names-error";
      errorNotice.className = "text-danger mt-2";
      this.inputTarget.insertAdjacentElement("afterend", errorNotice);
    }
    errorNotice.innerText = message;
  }

  resetError() {
    const errorNotice = document.querySelector("#color-names-error");
    if (errorNotice) {
      errorNotice.remove();
    }
  }

  disconnect() {
    if (this.tagify) {
      this.tagify.destroy();
    }
  }
}
