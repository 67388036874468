import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tab"
export default class extends Controller {
  static values = {
    frameId: String, // ID of the Turbo Frame to reload
  };

  connect() {
    this.element.addEventListener("shown.bs.tab", this.refreshFrame.bind(this));
  }

  refreshFrame() {
    const frame = document.getElementById(this.frameIdValue);
    if (frame) {
      frame.src = frame.getAttribute("src"); // Reload the Turbo Frame
    }
  }
}
