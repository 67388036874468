import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["accountType", "accountName", "firstName", "lastName"];

  onAccountTypeChange(_event) {
    const accountType = this.accountTypeTarget.value;
    if (accountType === "residential") {
      const accountName = this.accountNameTarget;
      if (accountName) {
        const firstName = this.firstNameTarget.value;
        const lastName = this.lastNameTarget.value;
        accountName.value = `${firstName} ${lastName}`;
      }
    }
  }
}
