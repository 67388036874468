import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="users"
export default class extends Controller {
  invite(e) {
    const button = e.currentTarget;
    const url = button.dataset.url;

    const spinner = document.createElement("span");
    spinner.classList.add("spinner-border", "spinner-border-sm");
    spinner.setAttribute("role", "status");
    button.replaceWith(spinner);

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
    }).then((response) => {
      const message = document.createElement("span");
      if (response.ok) {
        message.classList.add("text-success");
        message.innerText = "Sent";
      } else {
        message.classList.add("text-danger");
        message.innerText = "Error";
      }
      spinner.replaceWith(message);
    });
  }
}
